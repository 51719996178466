<template>
  <aside v-if="isMobile" class="l-sidebar-mobile">
    <!-- 手機版 -->
    <div class="l-section">
      <div class="l-ad__wrapper" style="min-height: 250px;">
        <div id="div-gpt-ad-1617869450309-0" style="width: 300px; height: 250px;" />
      </div>
    </div>
    <div>
      <h2 class="l-section__title-with-hr">
        人氣{{ type === 'video' ? '影音' : '新聞' }}
        <img src="https://static.ctwant.com/images/dist/fire.svg" width="16" height="21" alt="人氣新聞強調圖示">
      </h2>
      <hr>
      <div id="mobile-sidebar-hot" class="l-section__content-list hotnews">
        <nuxt-link
          v-for="(i, index) in data.popular.slice(0, 3)"
          :key="type === 'video' ? i.video_id : i.article_id"
          :to="`/${type}/${type === 'video' ? i.video_id : i.article_id}/`"
          :title="`前往${type === 'video' ? '影音' : '新聞'}頁`"
          class="m-card-s m-card-s--sidebar"
        >
          <div class="m-card-s__img-container">
            <div v-if="i.type !== 'general'" class="e-type--bg m-card-s__type">
              <span :class="articleType(i).style" class="e-type">
                {{ articleType(i).text }}
              </span>
            </div>
            <LazyImage :src="i.cover.xs" :alt="i.caption" class="m-card-s__img" width="136" height="72" />
          </div>
          <div class="m-card-s__content">
            <h3 class="m-card-s__title m-card--mle-2">
              {{ i.title }}
            </h3>
            <time :datetime="i.publish_at" class="e-time">
              <img src="https://static.ctwant.com/images/dist/clock.svg" class="m-card__clock" width="14" height="14" alt="發佈時間圖示">
              {{ i.publish_at }}
              <img
                v-if="index <= 2"
                src="https://static.ctwant.com/images/dist/fire.svg"
                class="m-card__hot"
                width="16"
                height="21"
                alt="人氣新聞強調圖示"
              >
            </time>
          </div>
        </nuxt-link>
        <!-- 人氣新聞 - 6 原生廣告 -->
        <div id="div-gpt-ad-1634112351745-0" class="m-card-s m-card-s--sidebar" style="width: 100%; max-height: 72px;" />
      </div>
    </div>
    <!-- <div class="l-section">
      <h2 class="l-section__title">
        關鍵熱搜
      </h2>
      <div id="mobile-sidebar-tag" class="l-tags__wrapper">
        <nuxt-link
          v-for="(tag, index) in data.tags.slice(0, 6)"
          :key="index"
          :to="`/search/${tag.trim()}/`"
          :title="`前往搜尋: ${tag}的頁面`"
          :rel="tag.length > 6 ? 'nofollow' : ''"
          class="e-tag l-tags__item"
        >
          {{ tag }}
        </nuxt-link>
      </div>
    </div> -->
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'
import articleHelpers from '@/utils/articleHelpers.js'
import LazyImage from '@/components/LazyImage.vue'

export default {
  components: {
    LazyImage
  },
  mixins: [articleHelpers],
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
      required: true
    },
    type: {
      type: String,
      default: 'article'
    }
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/modules/_sidebar';
</style>
